@charset "UTF-8";
/* You can add global styles to this file, and also import other style files */
html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-horizontal-stepper-header-container {
  margin-top: 10px;
  display: none !important;
}

.mat-horizontal-content-container {
  padding: 0 !important;
}

.mat-icon {
  position: relative;
  top: 5px;
  color: #5e5e5e;
}

.ui-widget-header,
a {
  -webkit-transition: all 0.2s ease;
}

.backward,
.btn_1:hover,
.forward,
a,
a.btn_1:hover,
a.modal-close:hover,
a:focus,
a:hover {
  text-decoration: none;
}

#copy,
#lang ul li a,
#logo,
.btn_1,
.chromeframe,
.main_title,
a.btn_1,
nav ul.menu {
  text-align: center;
}

.chromeframe {
  background: #ddd;
  color: #000;
  padding: 0.2em;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
}

.chromeframe a {
  color: #ed1c24;
}

a,
body {
  color: #555;
}

html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-size: 13px;
  line-height: 1.6;
  background-color: #fff;
  font-family: Poppins, Helvetica, Arial, sans-serif;
}

a {
  transition: all 0.2s ease;
  outline: 0;
}

a:focus,
a:hover {
  color: #6CC;
  outline: 0;
}

.title {
  border-bottom: 1px solid #ededed;
  padding: 0 20px 20px;
  margin: 0 -20px 20px;
}

.title span {
  color: #999;
}

.title h3,
h3,
h4,
h5 {
  color: #333;
}

.title h3 {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  padding: 0;
}

.main_title {
  font-size: 16px;
  margin-bottom: 30px;
}

.main_title h2 {
  font-weight: 600;
  letter-spacing: -1px;
  font-size: 30px;
  margin-bottom: 0;
  margin-top: 0;
  color: #333;
}

.main_title span {
  display: block;
  width: 40px;
  height: 4px;
  background-color: #ededed;
  margin: auto auto 15px;
  -webkit-border-radius: 5 px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.main_title p {
  font-weight: 300;
  font-size: 20px;
}

h4.styled {
  font-size: 24px;
  margin-bottom: 20px;
}

h4.styled span {
  font-size: 18px;
  display: block;
  margin-top: 5px;
  color: #555;
  font-weight: 400;
}

h4.styled sup {
  font-size: 12px;
  color: #999;
}

#caption h1 {
  font-size: 46px;
  font-weight: 400;
  margin: 0;
  padding: 0;
}

#caption h2,
.top_in h3 {
  font-weight: 300;
  padding: 0;
  margin: 0;
}

#caption h2 {
  font-size: 24;
}

.top_in h3 {
  position: absolute;
  left: 100px;
  top: 15px;
  display: inline-block;
  font-size: 30px;
}

.box_feat h3 {
  margin-top: 0;
  font-size: 18px;
}

hr {
  margin-top: 20px;
  margin-bottom: 40px;
  border: 0;
  border-top: 1px solid #ededed;
}

p {
  margin-bottom: 20px;
}

#lang ul {
  position: fixed;
  top: 30px;
  left: 30px;
  list-style: none;
  padding: 0;
  margin: 0;
}

#lang ul li {
  display: inline;
  margin-left: 5px;
}

#lang ul li a {
  color: #fff;
  display: inline-block;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.5);
  width: 40px;
  height: 40px;
  font-size: 12px;
  line-height: 40px;
}

#lang ul li a#active,
#lang ul li a:hover {
  background: #fff;
  color: #111;
  text-decoration: none;
}

ul#lang_mobile {
  display: none;
}

a.modal-close {
  color: #999;
  font-size: 28px;
  position: absolute;
  top: 12px;
  right: 20px;
  z-index: 9;
  outline: 0;
}

a.modal-close:hover {
  color: #333;
}

.btn_1,
a.btn_1 {
  font-size: 13px;
  border: 0;
  font-weight: 600;
  padding: 8px 20px;
  display: inline-block;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background: #6CC;
  color: #fff;
}

.btn_1:hover,
a.btn_1:hover {
  background: #333;
}

.backward,
.forward {
  border: none;
  display: inline-block;
  cursor: pointer;
  outline: 0;
  position: relative;
  width: 30px;
  height: 30px;
  line-height: 1;
  padding: 0;
  margin: 0;
  background: 0 0;
  color: #6CC;
}

button[disabled].backward,
button[disabled].forward {
  display: none;
}

.backward:before,
.forward:before {
  font-family: ElegantIcons;
  text-decoration: inherit;
  font-weight: 400;
  text-transform: none;
  font-size: 30px;
}

a#more_demos,
a#purchase {
  position: fixed;
  top: 37px;
  display: inline-block;
  padding: 5px 8px;
  font-size: 12px;
  background-color: #C03;
  z-index: 99;
  font-weight: 600;
}

.forward:before {
  content: "=";
}

.backward:before {
  content: "<";
}

.backward:hover,
.forward:hover {
  color: #333 !important;
}

a#more_demos {
  left: 140px;
  color: #fff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

a#purchase {
  left: 240px;
  color: #fff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

#complete button,
ul.additional_info li a {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
}

a#more_demos:hover,
a#purchase:hover {
  text-decoration: none;
  background-color: #111;
}

body,
html {
  height: 100%;
}

#wrapper {
  position: relative;
  min-height: 100%;
}

#modal-about,
#modal-blog,
#modal-contacts,
#modal-offers {
  background: #fff;
  background: rgb(255, 255, 255);
  display: none;
  left: 0;
  min-height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 997;
}

#slides {
  position: fixed !important;
  top: 0;
  left: 0;
  z-index: -3;
  background-color: #000;
}

ul.slides-container li img {
  opacity: 0.8;
}

#slides .slides-container {
  display: none;
}

#slides .scrollable {
  position: relative;
  top: 0;
  left: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  height: 100%;
}

#slides .scrollable:after {
  content: "";
  display: table;
  clear: both;
}

#complete button,
nav ul.menu li,
nav ul.menu li a {
  display: inline-block;
}

.slides-container p {
  position: absolute;
  bottom: 40px;
  left: 25px;
  font-size: 20px;
}

#form_container,
#top-wizard,
nav ul.menu li a {
  position: relative;
}

#caption {
  position: fixed;
  left: 30px;
  bottom: 30px;
  color: #fff;
  text-shadow: 5px 5px 20px rgb(0, 0, 0);
}

#logo {
  padding: 15px;
}

#copy,
#location,
#main_container {
  position: absolute;
}

nav ul.menu li a:hover {
  text-decoration: none;
  background-color: #555;
}

#main_container {
  width: 320px;
  height: 100%;
  right: 0;
  top: 0;
  background-color: rgb(255, 255, 255);
}

#copy {
  bottom: 15px;
  width: 100%;
}

#form_container {
  background-color: #fff;
}

#middle-wizard {
  padding: 10px;
}

#bottom-wizard {
  padding: 15px 20px;
  border-top: 1px solid #ededed;
}

#bottom-wizard strong {
  float: left;
  margin-top: 7px;
  color: #999;
}

.ui-widget-content a,
.ui-widget-header a {
  color: #222;
}

.ui-widget-content {
  background: #ededed;
  color: #222;
}

.ui-widget-header {
  background: #d11c5d;
  transition: all 0.2s ease;
}

.ui-progressbar {
  height: 3px;
  text-align: left;
}

#complete,
#complete h3,
.box_feat,
.box_feat i {
  text-align: center;
}

.ui-progressbar .ui-progressbar-value {
  height: 100%;
}

#location {
  color: #999;
  font-weight: 600;
  right: 20px;
  top: 25px;
  font-size: 12px;
}

#complete {
  padding: 45px 0;
}

#complete h3 {
  margin-bottom: 30px;
  font-size: 18px;
}

#complete i {
  color: #cacaca;
  margin: 0 0 10px;
  font-size: 80px;
  padding: 0;
}

#complete button {
  border-radius: 3px;
  font-size: 16px;
  border: 2px solid #8dc63f;
  color: #8dc63f;
  padding: 15px 25px;
  text-decoration: none;
  cursor: pointer;
  font-weight: 600;
  text-transform: uppercase;
  outline: 0;
  background: #fff;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

#complete button:hover {
  background: #333;
  color: #fff;
  border: 2px solid #333;
}

.form-control::-moz-placeholder {
  color: #555;
  opacity: 1;
  padding-top: 2px;
}

.form-control::-webkit-input-placeholder {
  color: #555;
  padding-top: 2px;
}

.form-control:-ms-input-placeholder,
.placeholder {
  color: #555;
  padding-top: 2px;
}

::-ms-clear {
  display: none;
}

input.form-control,
select.form-control,
textarea.form-control {
  background: #f7f7f7;
  border: 1px solid #ececec;
  border-radius: 0;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: none;
  color: #555;
  height: 35px;
  font-size: 14px;
  font-weight: 400;
}

input.form-control:focus,
select.form-control:focus,
textarea.form-control:focus {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #333;
  border: 1px solid #ddd;
}

input.qtyminus,
input.qtyplus {
  text-indent: -9999px;
  box-shadow: none;
}

.form-group {
  padding-bottom: 0;
  margin-bottom: 15px;
  position: relative;
}

.form-group.options {
  border-bottom: 1px solid #ededed;
  padding: 15px;
  margin: 0 -20px;
}

.form-group em {
  display: inline-block;
  margin-top: 5px;
  font-style: normal;
  position: relative;
  padding-left: 40px;
}

.dd-desc,
.dd-option,
.dd-selected {
  display: block;
  overflow: hidden;
}

.form-group.options em i {
  position: absolute;
  left: 0;
  top: -7px;
  font-size: 28px;
}

.form-group.first {
  padding-top: 0;
  margin-top: -5px;
}

.form-group.options.last {
  margin-bottom: 0;
  border-bottom: 0;
  padding-bottom: 0;
}

.form-group i {
  font-size: 20px;
  position: absolute;
  right: 30px;
  top: 10px;
  color: #777;
}

.dd-select {
  border-radius: 0 !important;
  border: 1px solid #ececec !important;
  position: relative;
  cursor: pointer;
  height: 40px;
  font-weight: 400 !important;
  background-color: #f7f7f7 !important;
  font-size: 14px !important;
}

.dd-option-selected,
.dd-option:hover {
  background: #f9f9f9 !important;
}

.dd-desc {
  color: #aaa;
  font-weight: 400;
}

.dd-selected,
â€‹ label.dd-selected-text {
  font-weight: 400 !important;
}

.dd-selected {
  padding: 0 0 0 10px !important;
  color: #555 !important;
}

.dd-pointer {
  width: 0;
  height: 0;
  position: absolute;
  right: 10px;
  top: 50%;
  margin-top: -3px;
}

.dd-pointer-down {
  border: 5px solid transparent;
  border-top: solid 5px #999 !important;
}

.dd-pointer-up {
  border: 5px solid transparent !important;
  border-bottom: solid 5px #000 !important;
  margin-top: -8px;
}

.dd-option {
  padding: 10px;
  padding-right: 0 !important;
  border-bottom: solid 1px #ddd;
  text-decoration: none;
  color: #333;
  cursor: pointer;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
}

.dd-options > li:last-child > .dd-option {
  border-bottom: none;
}

.dd-options li .dd-option {
  height: 45px;
  line-height: 25px !important;
}

.dd-option-text,
.dd-option-text:hover {
  line-height: 14px !important;
}

.dd-option-selected {
  color: #444 !important;
}

.dd-option-image,
.dd-selected-image {
  vertical-align: middle;
  float: left;
  margin-right: 10px !important;
  max-width: 40px;
}

.dd-image-right {
  float: right;
  margin-right: 10px !important;
  margin-left: 5px;
}

.dd-container {
  position: relative;
}

.dd-option-text {
  color: #777 !important;
}

.dd-selected-image {
  margin-top: 7px;
}

.dd-options label,
.dd-select label {
  font-weight: 400 !important;
  cursor: pointer !important;
}

input#website {
  display: none;
}

label.error {
  font-size: 11px;
  position: absolute;
  top: -28px;
  right: -15px;
  z-index: 99;
  height: 25px;
  line-height: 25px;
  background-color: #e34f4f;
  color: #fff;
  font-weight: 400;
  padding: 0 6px;
}

label.error:after {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 0 6px 6px 0;
  border-color: transparent #e34f4f;
  display: block;
  width: 0;
  z-index: 1;
  bottom: -6px;
  left: 20%;
}

.qty-buttons {
  position: relative;
  width: 100%;
  height: 38px;
  display: inline-block;
}

input.qty {
  width: 100%;
}

input.qtyminus,
input.qtyplus {
  position: absolute;
  width: 32px;
  height: 22px;
  border: 1px solid #ececec;
  outline: 0;
  cursor: pointer;
  -webkit-appearance: none;
  border-radius: 0;
}

/* input.qtyplus {
    background: url(../img/plus.png) center center no-repeat #fff;
    right: 0;
    top: 0
}

input.qtyminus {
    background: url(../img/minus.png) center center no-repeat #fff;
    right: 0;
    top: 18px
} */
#get_directions .btn,
.box_feat i {
  background-color: #6CC;
  color: #fff;
}

.box_feat {
  margin-bottom: 30px;
  border: 1px solid #ededed;
  padding: 20px 20px 0;
  border-top: 1px solid #6CC;
}

.box_feat i {
  font-size: 40px;
  line-height: 90px;
  margin: 0 0 15px;
  display: inline-block;
  border: 5px solid #cff6f6;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  width: 90px;
  height: 90px;
}

#modal-about .top_in {
  margin-bottom: 0;
}

blockquote.testimonial {
  background: #faf9ee;
  border-radius: 5px;
  border: 0;
  font-family: Georgia, serif;
  font-style: italic;
  margin: 0;
  padding: 30px 30px 30px 50px;
  position: relative;
}

blockquote.testimonial:before {
  left: 18px;
  top: 25px;
  color: #555;
  content: "“";
  font-size: 60px;
  font-style: normal;
  line-height: 1;
  position: absolute;
}

blockquote.testimonial p {
  font-family: Georgia, serif;
  font-style: italic;
  font-size: 16px;
  line-height: 1.4;
}

.testimonial-arrow-down {
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-top: 16px solid #faf9ee;
  height: 0;
  margin: 0 0 0 -8px;
  position: relative;
  left: 50%;
  width: 0;
}

.testimonial-author {
  width: 200px;
  margin: auto;
}

.testimonial-author .img-thumbnail {
  float: left;
  margin-right: 10px;
}

.testimonial-author .img-thumbnail img {
  max-width: 60px;
}

.testimonial-author strong {
  color: #111;
  display: block;
  padding-top: 20px;
}

.testimonial-author span {
  color: #666;
  display: block;
  font-size: 12px;
  font-style: italic;
}

.testimonial-author p {
  color: #999;
  margin: 0 0 0 25px;
  text-align: left;
  line-height: 1.1;
}

#confirm div,
#status,
#status div,
.box_style_1,
a.modal-close.booking {
  text-align: center;
}

ul.additional_info {
  list-style: none;
  padding: 0;
  margin: 25px 0 30px;
  font-size: 30px;
}

ul.additional_info li {
  display: inline-block;
  margin: 0 5px 10px 0;
}

ul.additional_info li a {
  display: inline-block;
  background-color: #f6f6f6;
  border-radius: 3px;
  width: 50px;
  height: 50px;
  line-height: 60px;
  text-align: center;
}

ul.additional_info li:last-child {
  margin-right: 0;
}

a.modal-close.booking {
  position: static;
  font-size: 13px;
  font-weight: 600;
  padding: 8px 20px;
  display: inline-block;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background: #6CC;
  color: #fff;
}

a.modal-close.booking:hover {
  text-decoration: none;
  background: #333;
}

#map {
  width: 100%;
  height: 350px;
}

#get_directions {
  padding: 10px;
  background-color: #333;
  margin-bottom: 30px;
}

#get_directions .btn {
  padding: 10px 25px;
  font-weight: 600;
  height: 40px;
  outline: 0;
}

ul#contact_info,
ul#reach_us,
ul#social {
  list-style: none;
  margin: 0 0 20px;
  padding: 0;
}

#get_directions .btn:hover {
  background-color: #555;
}

ul#social {
  font-size: 32px;
}

ul#social li {
  display: inline-block;
}

ul#contact_info li {
  margin-bottom: 10px;
}

ul#contact_info li strong {
  display: block;
}

ul#reach_us li {
  position: relative;
  padding-left: 60px;
  padding-bottom: 15px;
}

ul#reach_us li h5 {
  margin-bottom: 5px;
}

ul#reach_us li i {
  font-size: 40px;
  position: absolute;
  left: 0;
  top: 0;
  color: #6CC;
}

.nomargin {
  padding: 0;
  margin: 0;
}

.add_bottom_15 {
  margin-bottom: 15px;
}

.add_bottom_20 {
  margin-bottom: 20px;
}

.add_bottom_30 {
  margin-bottom: 30px;
}

.add_bottom_45 {
  margin-bottom: 45px;
}

.add_bottom_60 {
  margin-bottom: 60px;
}

.add_bottom_75 {
  margin-bottom: 75px;
}

.add_top_20 {
  padding-top: 20px;
}

.add_top_60 {
  padding-top: 60px;
}

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  bottom: 0;
  background-color: #fff;
  z-index: 999;
}

.footer_in,
.top_in {
  background-color: #efefef;
}

#status {
  width: 140px;
  height: 130px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -65px 0 0 -70px;
  display: table;
}

#status div {
  display: table-cell;
  vertical-align: middle;
}

#status img {
  display: block;
  margin: auto auto 10px;
}

.top_in {
  position: relative;
  padding: 20px 20px 20px 90px;
  height: 65px;
  margin-bottom: 60px;
}

.top_in img {
  position: absolute;
  left: 20px;
  top: 10px;
}

.footer_in {
  position: relative;
  padding: 20px;
}

.container_gray {
  background-color: #f8f8f8;
  margin-bottom: 45px;
  padding-top: 60px;
  border-bottom: 1px solid #ededed;
}

.box_style_1 {
  padding: 0 30px 0 0;
  border-right: 1px solid #e8e8e8;
  margin-top: 20px;
}

.box_style_1.last {
  border-right: none;
}

.box_style_1 h3 {
  margin-top: 15px;
  font-size: 22px;
  font-weight: 600;
}

.box_style_1 span {
  display: block;
  width: 40px;
  height: 4px;
  background-color: #6CC;
  margin: auto;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

#confirm {
  display: table;
  width: 100%;
  height: 100%;
}

#confirm div {
  display: table-cell;
  vertical-align: middle;
  width: 300px;
  height: 200px;
  padding: 30px;
}

#confirm div h1 {
  color: #333;
  margin-top: 0;
}

#confirm div h4 {
  color: #6C3;
}

#confirm div p {
  padding: 0;
  margin: 0;
}

.dd-selected, .dd-option {
  text-transform: uppercase;
}

ul.dd-options.dd-click-off-close.ng-star-inserted {
  background-color: #f8f9fa;
}

::ng-deep .mat-mdc-form-field-error {
  display: block;
}

.all-spance span {
  display: block;
}

@media (max-width: 678px) {
  #banner-home {
    padding: 100px 0 150px 0;
  }
}
.success img {
  max-width: 100px;
}

nav.booking {
  background-color: #fff;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #f7f7f7;
}

.des-img img {
  height: 200px;
  object-fit: cover;
}

td,
th {
  border: 1px solid #ddd;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

tr:hover {
  background-color: #ddd;
}

th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #784ac4;
  color: white;
}

app-bookingform {
  padding: 0 10px;
  max-width: 400px;
  margin: 0 auto;
}

.nav-tabs .nav-link.active {
  background-color: #115acc;
  color: #fff;
  border-color: #115acc;
  border-radius: 0;
}

.wizard i, nav.booking i {
  display: none;
}

button.job-box-cta {
  background-color: #246df8;
  color: #fff;
  border: 0;
  padding: 10px 20px;
}

li a {
  text-transform: capitalize !important;
}

.error, mat-error {
  display: block;
}

mat-datepicker-toggle.mat-datepicker-toggle {
  position: absolute;
  top: 25px;
  right: 0px;
}